import * as _util3 from "element-ui/lib/utils/util";
var _util2 = "default" in _util3 ? _util3.default : _util3;
var exports = {};
exports.__esModule = true;
var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
  return typeof obj;
} : function (obj) {
  return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
};
exports.isVNode = isVNode;
var _util = _util2;
function isVNode(node) {
  return node !== null && (typeof node === "undefined" ? "undefined" : _typeof(node)) === "object" && (0, _util.hasOwn)(node, "componentOptions");
}
;
export default exports;
export const __esModule = exports.__esModule;
const _isVNode = exports.isVNode;
export { _isVNode as isVNode };